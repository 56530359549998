import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import Tick from "../images/Tick.svg";

const PricingSection = ({ pricingData }) => {
	const cardStyle = {
		padding: "2px", // This creates space for the gradient border
		borderRadius: "12px",
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)", // Gradient border
	};

	const contentStyle = {
		borderRadius: "11px",
		boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06)",
		background: "white", // Content background (can be 'white' or any color)
		minHeight: "100%",
		// Or 'p-4' if using Bootstrap classes
	};

	// Helper function to render a single price box
	const renderPriceBox = (priceBox) => (
		<div
			style={{ border: "1px solid  #D6D6D6", borderRadius: "12px" }}
			className="text-center bg-white"
		>
			<div className="px-5 pt-5 pb-4">
				<h4 className="mb-4 fs-3"> {priceBox.title}</h4>

				<p className="display-3 Jakarta-Bold text-black">
					<span className="fs-4">$</span>
					{priceBox.price}
				</p>
				<Button
					size="lg"
					className="my-4 gradient-button px-5 py-2 text-uppercase"
					href={priceBox.button.link}
				>
					{priceBox.button.text}
				</Button>
			</div>
			<div
				style={{ borderTop: "1px solid  #D6D6D6" }}
				className="text-start pt-4 mx-5 pb-4"
			>
				<h5 className="text-schema-grey pb-3">{priceBox.features.title}</h5>

				{priceBox.features.items.map((item, index) => (
					<div className="d-flex align-items-center pb-3" key={index}>
						<img
							className=" d-none d-lg-inline-block"
							src={Tick}
							alt="QuoteStar Logo"
							style={{
								width: "17px",
							}}
						/>
						<p className="mb-0 ms-3 d-inline-block pb-0">{item.title}</p>
					</div>
				))}
			</div>
		</div>
	);
	const renderPriceBoxMiddle = (priceBox) => (
		<div style={{ ...cardStyle }} className="text-center">
			<div style={{ ...contentStyle }}>
				<div className="px-5 pt-5 pb-4">
					<h4 className="mb-4 fs-3"> {priceBox.title}</h4>

					<p className="display-3 Jakarta-Bold text-black">
						<span className="fs-4">$</span>
						{priceBox.price}
					</p>
					<Button
						size="lg"
						className="my-4 gradient-button px-5 py-2 text-uppercase"
						href={priceBox.button.link}
					>
						{priceBox.button.text}
					</Button>
				</div>
				<div
					style={{ borderTop: "1px solid  #D6D6D6" }}
					className="text-start pt-4 mx-5 pb-4"
				>
					<h5 className="text-schema-grey pb-3">{priceBox.features.title}</h5>

					{priceBox.features.items.map((item, index) => (
						<div className="d-flex align-items-center pb-3" key={index}>
							<img
								className=" d-none d-lg-inline-block"
								src={Tick}
								alt="QuoteStar Logo"
								style={{
									width: "17px",
								}}
							/>
							<p className="mb-0 ms-3 d-inline-block pb-0">{item.title}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);

	return (
		<Container className="">
			<Row className="mb-5 justify-content-center">
				<Col className="text-center" lg={6}>
					{parse(pricingData.title)}
					<p className="pt-3 pb-7">{pricingData.description}</p>
					{parse(pricingData.secondaryTitleCopy)}
				</Col>
			</Row>
			<Row className=" g-5">
				<Col md={6} lg={4}>
					{renderPriceBox(pricingData.priceBox1)}
				</Col>
				<Col md={6} lg={4}>
					{renderPriceBoxMiddle(pricingData.priceBox2)}
				</Col>
				<Col md={6} lg={4}>
					{renderPriceBox(pricingData.priceBox3)}
				</Col>
			</Row>
		</Container>
	);
};

export default PricingSection;
