import React, { useState } from "react";
import {
	Container,
	Form,
	Button,
	Alert,
	Row,
	Col,
	Spinner,
} from "react-bootstrap";
import parse from "html-react-parser";
import { navigate } from "gatsby";

const BrokerSignUpForm = ({ title }) => {
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		phoneNumber: "",
		message: "",
		agreeToTerms: false,
		companyName: "",
	});
	const [validated, setValidated] = useState(false);
	const [showTermsError, setShowTermsError] = useState(false);
	const [submitStatus, setSubmitStatus] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false); // New state for tracking form submission

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});

		if (name === "agreeToTerms") {
			setShowTermsError(!checked);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;

		if (form.checkValidity() === false || !formData.agreeToTerms) {
			setShowTermsError(!formData.agreeToTerms);
			setValidated(true);
		} else {
			setIsSubmitting(true); // Start submitting
			const API_KEY = process.env.GATSBY_MY_SECRET_API_KEY;
			try {
				const response = await fetch(
					"/.netlify/functions/createAirtablePotentialBroker",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": API_KEY,
						},
						body: JSON.stringify({
							fields: {
								"Full Name": formData.fullName,
								Email: formData.email,
								"Phone Number": formData.phoneNumber,
								Message: formData.message,
								companyName: formData.companyName,
								// Additional fields can be included here
							},
						}),
					}
				);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				// Handle successful form submission
				setSubmitStatus("success");
				setFormData({
					fullName: "",
					email: "",
					phoneNumber: "",
					message: "",
					agreeToTerms: false,
					companyName: "",
				});
				setShowTermsError(false);
				setValidated(false);
				navigate("/thank-you-broker-signup");
			} catch (error) {
				alert("An error has occurred. Please try again later.");
				setSubmitStatus("error");
				setIsSubmitting(false);
			} finally {
				// End submitting
			}
		}
	};

	return (
		<Container>
			{parse(title)}
			<Form
				className="mt-4"
				noValidate
				validated={validated}
				onSubmit={handleSubmit}
			>
				<Row className="g-md-5 g-4">
					<Col lg={6}>
						<Form.Group className="pb-3" controlId="fullName">
							<Form.Label className="bold">Full Name *</Form.Label>
							<Form.Control
								className="py-3"
								required
								type="text"
								name="fullName"
								value={formData.fullName}
								onChange={handleChange}
								placeholder="Enter your full name"
							/>
							<Form.Control.Feedback type="invalid">
								Please provide your full name.
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="pb-3" controlId="companyName">
							<Form.Label className="bold">Company Name *</Form.Label>
							<Form.Control
								className="py-3"
								required
								type="text"
								name="companyName"
								value={formData.companyName}
								onChange={handleChange}
								placeholder="Enter your company name"
							/>
							<Form.Control.Feedback type="invalid">
								Please provide your company name.
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="pb-3" controlId="email">
							<Form.Label className="bold">Email address *</Form.Label>
							<Form.Control
								className="py-3"
								required
								type="email"
								pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
								name="email"
								value={formData.email}
								onChange={handleChange}
								placeholder="Enter your email"
							/>
							<Form.Control.Feedback type="invalid">
								Please provide a valid email.
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group className="pb-3" controlId="phoneNumber">
							<Form.Label className="bold">Phone number *</Form.Label>
							<Form.Control
								className="py-3"
								required
								type="tel"
								pattern="\+?[0-9]{1,15}"
								name="phoneNumber"
								value={formData.phoneNumber}
								onChange={handleChange}
								placeholder="Enter your phone number"
							/>
							<Form.Control.Feedback type="invalid">
								Please provide a valid phone number.
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="agreeToTerms">
							<Form.Check
								required
								type="checkbox"
								name="agreeToTerms"
								label={
									<div>
										I agree to Quotestar's{" "}
										<a
											href="/policies/terms-and-conditions-broker"
											target="_blank"
											className="black-link"
											rel="noopener noreferrer"
										>
											Privacy Policy and T&Cs
										</a>
										.
									</div>
								}
								checked={formData.agreeToTerms}
								onChange={handleChange}
								isInvalid={!formData.agreeToTerms && showTermsError}
							/>
							{showTermsError && (
								<div className="invalid-feedback" style={{ display: "block" }}>
									You must agree to the terms and conditions.
								</div>
							)}
						</Form.Group>
					</Col>
					<Col lg={6}>
						<Form.Group controlId="message">
							<Form.Label className="bold">Message *</Form.Label>
							<Form.Control
								className="bg-med-grey"
								required
								rows={13}
								as="textarea"
								name="message"
								value={formData.message}
								onChange={handleChange}
								placeholder="Enter your message"
							/>
							<Form.Control.Feedback type="invalid">
								Please provide a message.
							</Form.Control.Feedback>
						</Form.Group>
						<div className="text-end">
							<Button
								size="lg"
								className="my-4 w-100 w-md-auto gradient-button px-5 py-2"
								type="submit"
								disabled={isSubmitting} // Disable the button while submitting
							>
								{isSubmitting ? (
									<>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											className="me-2"
										/>
										Submitting...
									</>
								) : (
									"Send"
								)}
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
			{submitStatus === "success" && (
				<Alert variant="success">
					Your information has been submitted successfully.
				</Alert>
			)}
			{submitStatus === "error" && (
				<Alert variant="danger">
					There was an error submitting your information.
				</Alert>
			)}
		</Container>
	);
};

export default BrokerSignUpForm;
