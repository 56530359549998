import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";

import { Card } from "react-bootstrap";
import RecordForm from "../components/record-form";
import MultiStepForm from "../components/multi-step-form";
import HeroSectionBroker from "../components/broker-sign-up-hero";
import HowItWorksSectionBroker from "../components/how-it-works-section-broker";
import PricingSection from "../components/price-section-broker";
import WhyPartnerSectionBrokers from "../components/why-partner-section-brokers";
import BrokerSignupForm from "../components/broker-sign-up-form";
import FaqSection from "../components/faq-section";
import NewsletterBanner from "../components/newsletter-banner";

const BrokerSignUpPage = () => {
	const {
		wpBrokerSignUpPage: {
			seoFields,
			brokerSignUpPageFields: {
				ctaBannerSection,
				faqSection,
				formSection,
				heroSection,
				howItWorksSection,
				pricingSection,
				whyPartnerSection,
				hasPricingSection,
			},
		},
		site: {
			siteMetadata: { siteUrl },
		},
	} = useStaticQuery(graphql`
		query {
			wpBrokerSignUpPage {
				seoFields {
					metaTitle
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						link
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
						}
					}
				}
				brokerSignUpPageFields {
					hasPricingSection
					ctaBannerSection {
						title
					}
					faqSection {
						title
						description
						faq1 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq2 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq3 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq4 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
					}
					formSection {
						title
					}
					heroSection {
						title
						description
						button {
							text
							link
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					howItWorksSection {
						title
						video {
							publicUrl
						}
						item1 {
							title
							description
						}
						item2 {
							title
							description
						}
						item3 {
							title
							description
						}
						item4 {
							title
							description
						}
					}
					pricingSection {
						title
						description
						secondaryTitleCopy
						priceBox1 {
							title
							price
							button {
								text
								link
							}
							features {
								title
								items {
									title
								}
							}
						}
						priceBox2 {
							title
							price
							button {
								text
								link
							}
							features {
								title
								items {
									title
								}
							}
						}
						priceBox3 {
							title
							price
							button {
								text
								link
							}
							features {
								title
								items {
									title
								}
							}
						}
					}
					whyPartnerSection {
						title
						description
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						subsection1 {
							title
							description
						}
						subsection2 {
							title
							description
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Broker Sign Up",
				item: {
					url: `${siteUrl}/broker-sign-up`,
					id: `${siteUrl}/broker-sign-up`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFields.metaTitle}
				description={seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/broker-sign-up`,
					title: seoFields.opengraphTitle,
					description: seoFields.opengraphDescription,
					images: [
						{
							url: `${seoFields.image?.link}`,
							width: `${seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section className="py-5 py-lg-7">
						<HeroSectionBroker
							loginButton
							title={heroSection.title}
							subtitle={heroSection.description}
							buttonText={heroSection.button.text}
							buttonLink={heroSection.button.link}
							image={
								heroSection.image.localFile.childImageSharp.gatsbyImageData
							}
							altText={heroSection.image.altText}
						/>
					</section>
					<section>
						<HowItWorksSectionBroker
							title={howItWorksSection.title}
							video={howItWorksSection.video.publicUrl}
							benefit1Title={howItWorksSection.item1.title}
							benefit1Desc={howItWorksSection.item1.description}
							benefit2Title={howItWorksSection.item2.title}
							benefit2Desc={howItWorksSection.item2.description}
							benefit3Title={howItWorksSection.item3.title}
							benefit3Desc={howItWorksSection.item3.description}
							benefit4Title={howItWorksSection.item4.title}
							benefit4Desc={howItWorksSection.item4.description}
						/>
					</section>
					{hasPricingSection === true && (
						<section className="bg-med-grey py-5 py-lg-7">
							<PricingSection pricingData={pricingSection} />
						</section>
					)}
					<section className="bg-med-grey ">
						<WhyPartnerSectionBrokers
							img={
								whyPartnerSection.image.localFile.childImageSharp
									.gatsbyImageData
							}
							description={whyPartnerSection.description}
							imgAlt={whyPartnerSection.image.altText}
							title={whyPartnerSection.title}
							subsection1Title={whyPartnerSection.subsection1.title}
							subsection1Description={whyPartnerSection.subsection1.description}
							subsection2Title={whyPartnerSection.subsection2.title}
							subsection2Description={whyPartnerSection.subsection2.description}
						/>
					</section>
					<section id="form" className="py-4 py-md-5 py-lg-7">
						<BrokerSignupForm title={formSection.title} />
					</section>
					<section className="bg-med-grey py-5 py-lg-7">
						<FaqSection
							bgColour="#F5F5F5"
							title={faqSection.title}
							description={faqSection.description}
							faq1Question={faqSection.faq1.faqFields.question}
							faq1Answer={faqSection.faq1.faqFields.answer}
							faq2Question={faqSection.faq2.faqFields.question}
							faq2Answer={faqSection.faq2.faqFields.answer}
							faq3Question={faqSection.faq3.faqFields.question}
							faq3Answer={faqSection.faq3.faqFields.answer}
							faq4Question={faqSection.faq4.faqFields.question}
							faq4Answer={faqSection.faq4.faqFields.answer}
						/>
					</section>
					<NewsletterBanner title={ctaBannerSection.title} />
				</Layout>
			</div>
		</>
	);
};

export default BrokerSignUpPage;
