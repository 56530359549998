import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";

const WhyPartnerSectionBrokers = ({
	title,
	description,
	subsection1Title,
	subsection1Description,
	subsection2Title,
	subsection2Description,

	img,
	imgAlt,
}) => {
	return (
		<Container fluid className=" py-5 py-lg-7">
			<Row className="justify-content-between  gx-5 gy-5 gy-lg-0">
				<Col className="px-4 px-md-5" lg={6}>
					{parse(title)}{" "}
					<div
						className="mt-5 w-xl-70 w-lg-80"
						style={{
							borderRadius: "24px",
							overflow: "hidden",
							boxShadow: "0px 16px 19.8px 0px rgba(0, 69, 136, 0.12)",
						}}
					>
						<GatsbyImage className="w-100" image={img} alt={imgAlt} />
					</div>
				</Col>
				<Col lg={6} className="position-relative px-4 px-md-5">
					{parse(description)}{" "}
					<Row className="pt-4">
						<Col lg={6}>
							<h3 className="fs-4">{subsection1Title}</h3>
							<p>{subsection1Description}</p>
						</Col>
						<Col lg={6}>
							<h3 className="fs-4">{subsection2Title}</h3>
							<p>{subsection2Description}</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default WhyPartnerSectionBrokers;
